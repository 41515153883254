import React, {useEffect} from 'react';
import {DatePicker, Form, Input, InputNumber, Select} from "antd";
import {rules} from "../../utils/rules";

export function ExchangeForm({form}) {

    const isUSD = Form.useWatch(['from', 'currency'], form) === 'USD'

    return (
        <div>
            <Form.Item label="تاريخ" name="date" rules={rules.date}>
                <DatePicker style={{width: '100%'}}/>
            </Form.Item>


            <Form.Item label="الموضف" name="employee" rules={rules.input}>
                <Input/>
            </Form.Item>


            <Form.Item
                label="تحويل من"
                name={['from', 'currency']}
                rules={rules.input}
            >
                <Select defaultValue="Active">
                    <Select.Option value="USD">USD</Select.Option>
                    <Select.Option value="IQD">IQD</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item label="كمية" name={['from', 'amount']} rules={rules.input}>
                <InputNumber style={{width: '100%'}} min={0}/>
            </Form.Item>

            <Form.Item
                label="تحويل الى"
                // name={['to', 'currency']} rules={rules.input}
            >
                <div>{isUSD ? 'IQD' : 'USD'}</div>
            </Form.Item>

            <Form.Item label="كمية" name={['to', 'amount']} rules={rules.input}>
                <InputNumber style={{width: '100%'}} min={0}/>
            </Form.Item>


            <Form.Item label="المواصفات" name="description">
                <Input.TextArea rows={4}/>
            </Form.Item>
        </div>
    );
}
