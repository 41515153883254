import {Link, useNavigate} from "react-router-dom";
import moment from "moment";
import {sum, sumBy} from "lodash";

import {Shell} from "../../components/shell";
import {useFetch} from "../../api";
import {OwlTable} from "../../components/owl-table";
import {useMemo} from "react";
import {Button} from "antd";
import {formatNumber} from "../../utils/utils";
import {FileAddOutlined} from "@ant-design/icons";
import PageHeading from "../../components/page-heading";
import {RefreshButton} from "../../components/refresh-button";
import {useAuth} from "../../security";

export function Wages() {
    const {isAdmin} = useAuth()

    const {data = {}, isLoading, fetch} = useFetch({url: '/wage'})
    const dateF = (d) => moment(d).format('DD-MM')
    let navigate = useNavigate();


    const columns = useMemo(() => [
        {
            accessorFn: row => ` من ${dateF(row.date.start)} الي ${dateF(row.date.end)} `,
            id: 'date',
            header: 'التاريخ',
            cell: info => <Link to={`/wage/${info.row.original.id}`}>{info.getValue()}</Link>,
        },
        {
            accessorFn: row => sumBy(row.lines, 'days'),
            id: 'days',
            header: 'مجموع الايام',
        },
        {
            accessorFn: row => sum(row.lines.map(i => Number(i.cost) * Number(i.days))),
            id: 'cost',
            header: 'مجموع اليوميات',
            cell: info => formatNumber(info.getValue(), null, 0),
        },
    ], [data, isLoading]);

    return <Shell currentPage="wage" isLoading={isLoading}>
        <PageHeading
            title={'الآسبوعيات'}
            subtitle={'كشف اسبوعيات العمال.'}
        />
        <OwlTable
            data={data?.results || []}
            columns={columns}

            buttons={[isAdmin && <Button type="primary" onClick={() => navigate("/wage/new", {replace: true})}
                              icon={<FileAddOutlined/>}>
                اضافة جديد
            </Button>, <RefreshButton fetch={fetch} loading={isLoading}/>]}
        />
    </Shell>
}
