import React from 'react';
import {DatePicker, Form, Input, InputNumber, Select} from "antd";
import {rules} from "../../utils/rules";

export function LoanForm({form}) {

    const usd = Form.useWatch(['total', 'usd'], form);

    return (
        <div>
            <Form.Item label="تاريخ" name="date" rules={rules.date}>
                <DatePicker style={{width: '100%'}}/>
            </Form.Item>


            <Form.Item label="المدين" name="from" rules={rules.input}>
                <Input/>
            </Form.Item>

            <Form.Item
                label="النوع"
                name="type"
                rules={rules.input}
            >
                <Select>
                    <Select.Option value="Loan">دين</Select.Option>
                    <Select.Option value="Payback">سداد دين</Select.Option>
                </Select>
            </Form.Item>


            <Form.Item label="دولار" name={['total', 'usd']} rules={rules.input}>
                <InputNumber style={{width: '100%'}} min={0}/>
            </Form.Item>

            {usd > 0 && (<Form.Item label="سعر التحويل" name={['total', 'exchangeRate']} rules={rules.input}>
                <InputNumber style={{width: '100%'}} min={0}/>
            </Form.Item>)}

            <Form.Item label="دينار" name={['total', 'iqd']} rules={rules.input}>
                <InputNumber style={{width: '100%'}} min={0}/>
            </Form.Item>

            <Form.Item label="المواصفات" name="description">
                <Input.TextArea rows={4}/>
            </Form.Item>
        </div>
    );
}
