import {DebouncedInput} from "./debounced-input";

export function GlobalSearch({globalFilter, setGlobalFilter}) {

    return (
        <div>
            <DebouncedInput
                value={globalFilter ?? ''}
                onChange={value => setGlobalFilter(String(value))}
                className="p-2 font-lg shadow border border-block"
                placeholder="البحث ..."
            />
        </div>
    );
}
