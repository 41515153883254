import {useState} from "react";
import axios from 'axios'
import {getFullUrl, useFetch, useMutation} from "../../api";
import {OwlTable} from "../owl-table";
import React, {useMemo} from "react";
import {EditExchange} from "../../page/exchanges/edit-exchange";
import moment from "moment";
import {formatBytes, formatNumber} from "../../utils/utils";
import {Button, Form, Modal, Upload} from "antd";
import {useParams} from "react-router-dom";
import {ModalFormFooter} from "../modal-form-footer";
import {UploadForm} from "../forms/upload";
import {UploadOutlined} from "@ant-design/icons";
import {isEmpty} from "lodash";
import {useQueryClient} from "react-query";
import {PageSpinner} from "../page-spinner";


export function UploadEdit({text, id, fetch}) {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const {data, isLoading, fetch: fetchInfo} = useFetch({url: `/upload/${id}`, dontFetch: isEmpty(id) || !isModalOpen})

    const onOpen = async () => {
        setIsModalOpen(true)
        form.resetFields()
    }

    const onCancel = () => {
        setIsModalOpen(false);
        form.resetFields()
    }


    const usePatch = useMutation({url: `/upload/${id}`, method: "PATCH"})
    const useRemove = useMutation({url: `/upload/${id}`, method: "DELETE"})


    const onDelete = () => {
        useRemove.mutate({}, {
            onSuccess: () => {
                fetch()
                setIsModalOpen(false);
                onCancel()
            },
        })
    }

    const onOk = async () => {
        const values = await form.validateFields()
        usePatch.mutate(values, {
            onSuccess: () => {
                setIsModalOpen(false);
                fetch()
                fetchInfo()
                onCancel()
            },
        })
    }

    return (<div>
        <div className="cursor-pointer hover:text-blue-400" onClick={onOpen}>{text}</div>
        <Modal
            title={`Upload File`}
            visible={isModalOpen}
            destroyOnClose
            footer={<ModalFormFooter {...{
                onOk, onCancel, onDelete,
                onUpdateLoading: usePatch.isLoading,
                onDeleteLoading: useRemove.isLoading
            }}/>}
            width="95%"
            onCancel={onCancel}>

            <PageSpinner loading={isLoading}>
                <Form
                    name="basic"
                    form={form}
                    initialValues={data}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    autoComplete="off"
                >
                    <UploadForm form={form}/>

                </Form>
            </PageSpinner>
        </Modal>
    </div>)
}
