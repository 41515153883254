import React from 'react';
import {Form, Input, InputNumber, Select} from "antd";
import {rules} from "../../utils/rules";

export function UploadForm({form}) {

    return (
        <div>
            <Form.Item label="الوصف" name="description" rules={rules.input}>
                <Input/>
            </Form.Item>
        </div>
    );
}
