import React from 'react';
import {Form, Input, InputNumber, Select} from "antd";
import {rules} from "../../utils/rules";

export function WorkerForm({form}) {

    return (
        <div>
            <Form.Item label="الآسم" name="name" rules={rules.input}>
                <Input/>
            </Form.Item>


            <Form.Item label="الراتب الحالي" name="salary" rules={rules.input}>
                <InputNumber style={{width: '100%'}} min={0}/>
            </Form.Item>


            <Form.Item label="status" name="status" rules={rules.input}>
                <Select defaultValue="Active">
                    <Select.Option value="Active">Active</Select.Option>
                    <Select.Option value="Paused">Paused</Select.Option>
                    <Select.Option value="Terminated">Terminated</Select.Option>
                </Select>
            </Form.Item>
        </div>
    );
}
