import {Shell} from "../../components/shell";
import {Widgets} from "./widgets";
import {NavBox} from "./nav-box";

export function Main(props) {
    return (
        <Shell currentPage="main">
            <Widgets/>
            <NavBox/>
        </Shell>
    );
}
