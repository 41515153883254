import {flexRender} from "@tanstack/react-table";
import {Filter} from "./filter";

export function TableHead({table, isFilter}) {
    return table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
                <th {...{
                    key: header.id,
                    colSpan: header.colSpan,
                    style: {width: header.getSize()},
                    className: "px-2 py-3.5 text-right text-sm font-semibold text-gray-900 border-x-[1px]"
                }}

                >
                    {header.isPlaceholder
                        ? null
                        : <div
                            {...{
                                className: header.column.getCanSort()
                                    ? 'whitespace-nowrap cursor-pointer select-none'
                                    : '',
                                onClick: header.column.getToggleSortingHandler(),
                            }}
                        >
                            {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}
                            {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                            }[header.column.getIsSorted()] ?? null}
                            {isFilter && header.column.getCanFilter() ? (
                                <Filter column={header.column} table={table}/>
                            ) : null}
                        </div>
                    }
                    <div
                        {...{
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `resizer ${
                                header.column.getIsResizing() ? 'isResizing' : ''
                            }`,
                        }}
                    />
                </th>
            ))}
        </tr>
    ))
}

