import {Button, DatePicker, Form, Input, InputNumber, Modal, Select} from "antd";
import {useState} from "react";
import {useMutation} from "../../api";
import {useQueryClient} from "react-query";
import moment from "moment";
import {LoanForm} from "../../components/forms/loan";
import {FileAddOutlined} from "@ant-design/icons";


export function AddLoan(props) {

    const [form] = Form.useForm();
    const queryClient = useQueryClient()

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true)
        form.resetFields()
    }
    const onCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }
    const usePost = useMutation({url: '/loan'})


    const handleOk = async () => {
        const values = await form.validateFields()
        usePost.mutate(values, {
            onSuccess: (newData) => {
                setIsModalVisible(false)
                queryClient.setQueryData('/loan', (oldData = []) =>
                    ({...oldData, results: [...oldData.results, newData]}))
            },
        })
    };


    return (<div>
        <Button type="primary" onClick={showModal} icon={<FileAddOutlined/>}>
            إضافه دين جديد
        </Button>
        <Modal
            title="إصافة دين جديد"
            centered
            width={'70%'}
            destroyOnClose
            visible={isModalVisible}
            okText={"إضافة"}
            cancelText={"إلغاء"}
            onOk={handleOk}
            onCancel={onCancel}>
            <Form
                name="basic"
                form={form}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                initialValues={{
                    total: {
                        usd: 0,
                        iqd: 0
                    },
                    date: moment(),
                    type: 'Loan',
                }}
                autoComplete="off"
            >
                <LoanForm form={form}/>
            </Form>
        </Modal>
    </div>);
}

export default AddLoan;
