import {useState} from "react";
import {getFullUrl, useMutation} from "../../api";
import {Button, Form, message, Modal, Upload} from "antd";
import {FileAddOutlined, UploadOutlined} from "@ant-design/icons";
import {UploadForm} from "../forms/upload";
import {useQueryClient} from "react-query";


export function UploadModal({owner, fetch: reFetch}) {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null)

    const onOpen = () => {
        setIsModalOpen(true)
    }
    const onCancel = async () => {
        await reFetch()
        setFile(null)
        form.resetFields()
        setIsLoading(false)
        setIsModalOpen(false)
    }


    const handleUpload = async () => {

        setIsLoading(true)
        const values = await form.validateFields()

        const formData = new FormData();
        formData.append('file', file[0]);
        formData.append("owner", owner)
        formData.append("description", values.description)

        //
        fetch(getFullUrl('/upload'), {
            method: 'POST',
            body: formData,
        })
            .then((res) => res.json())
            .then(() => {
                message.success('upload successfully.');
            })
            .catch(() => {
                message.error('upload failed.');
            })
            .finally(() => {
                onCancel()
            });
    };


    const props = {
        onRemove: () => setFile(null),
        beforeUpload: (file) => {
            setFile([file]);
            return false;
        },
        fileList: file
    };

    return <div>
        <Button type="primary" icon={<FileAddOutlined/>} onClick={onOpen}>رفع ملف</Button>
        <Modal
            title={`رفع ملف`}
            centered
            visible={isModalOpen}
            destroyOnClose
            width="95%"
            onCancel={onCancel}
            footer={<div>
                <Button
                    type="primary"
                    onClick={handleUpload}
                    disabled={file === null}
                    loading={isLoading}

                    style={{
                        marginTop: 16,
                    }}
                >
                    {isLoading ? 'Uploading' : 'Start Upload'}
                </Button>
            </div>}
        >
            <Form
                name="basic"
                form={form}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                autoComplete="off"
            >
                <UploadForm form={form}/>

                <Upload {...props} data={owner}>
                    <Button icon={<UploadOutlined/>}>Select File</Button>
                </Upload>

            </Form>

        </Modal>
    </div>
}
