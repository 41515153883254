import {Button, Form, Input, Modal, Popconfirm, Select} from "antd";
import {useState} from "react";
import {rules} from "../../utils/rules";
import {useFetch, useMutation} from "../../api";
import {useQueryClient} from "react-query";
import {isEmpty, pick} from "lodash";
import {PageSpinner} from "../../components/page-spinner";


export function EditProject({id}) {

    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const {data, isLoading} = useFetch({url: `/project/${id}`, dontFetch: isEmpty(id) || !isModalVisible})

    const queryClient = useQueryClient()


    const showModal = () => {
        setIsModalVisible(true)
        form.resetFields()
    }
    const onCancel = () => {
        setIsModalVisible(false)
        form.resetFields()
    }

    const usePatch = useMutation({url: `/project/${id}`, method: "PATCH"})
    const useRemove = useMutation({url: `/project/${id}`, method: "DELETE"})

    const onDelete = async () => {
        useRemove.mutate({id}, {
            onSuccess: (newData) => {
                setIsModalVisible(false)

                queryClient.removeQueries(`/project/${id}`, {exact: true})

                queryClient.setQueryData('/project', (oldData = []) => {
                    return {...oldData, results: oldData.results.filter(i => i.id !== newData.id)}
                })
            },
        })
        setIsModalVisible(false)
    };


    const onOk = async () => {
        const values = await form.validateFields()

        if (data.code === values.code) delete values.code
        usePatch.mutate(values, {
            onSuccess: (newData) => {
                setIsModalVisible(false)

                queryClient.setQueryData(`/project/${id}`, () => newData)

                queryClient.setQueryData('/project', (oldData = []) =>
                    ({...oldData, results: oldData.results.map(i => i.id === id ? newData : i)}))
            },
        })
    };

    return (<div>
        <Button type="primary" onClick={showModal}>
            تعديل
        </Button>
        <Modal
            title="تعديل المشروع"
            centered
            width={'70%'}
            destroyOnClose
            visible={isModalVisible}
            onCancel={onCancel}
            footer={<div>
                <Button type="primary" onClick={onOk}>تعديل</Button>
                <Button onClick={onCancel}>إلغاء</Button>

                <Popconfirm
                    title="هل انت متآكد ؟"
                    onConfirm={onDelete}
                    okText="نعم"
                    cancelText="كلا"
                >
                    <Button type="primary" danger>
                        حذف
                    </Button>
                </Popconfirm>
            </div>}>
            <PageSpinner loading={isLoading}>
                <Form
                    name="basic"
                    form={form}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    initialValues={{
                        ...data
                    }}
                    autoComplete="off"
                >
                    <Form.Item
                        label="رمز المشروع"
                        name="code"
                        rules={rules.input}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="آسم المشروع"
                        name="name"
                        rules={rules.input}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="المواصفات"
                        name="description"
                        rules={rules.input}
                    >
                        <Input.TextArea rows={4}/>
                    </Form.Item>


                    <Form.Item
                        label="حالة المشروع"
                        name="status"
                        rules={rules.input}
                    >
                        <Select>
                            <Select.Option value="Active">فعال</Select.Option>
                            <Select.Option value="Inactive">متوقف</Select.Option>
                        </Select>
                    </Form.Item>

                </Form>
            </PageSpinner>
        </Modal>
    </div>);
}
