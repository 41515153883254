import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
} from "antd";
import { useState } from "react";
import { rules } from "../../utils/rules";
import { useFetch, useMutation } from "../../api";
import { useQueryClient } from "react-query";
import moment from "moment";
import { isEmpty, pick } from "lodash";
import { PageSpinner } from "../../components/page-spinner";

export function EditInvoice({ id, fetch = () => null }) {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data, isLoading } = useFetch({
    url: `/invoice/${id}`,
    dontFetch: isEmpty(id) || !isModalVisible,
  });

  const queryClient = useQueryClient();

  const showModal = () => {
    setIsModalVisible(true);
    form.resetFields();
  };
  const onCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const usePatch = useMutation({ url: `/invoice/${id}`, method: "PATCH" });
  const useRemove = useMutation({ url: `/invoice/${id}`, method: "DELETE" });

  const { data: { results: projects = [] } = {} } = useFetch({
    url: "/project",
  });

  const onDelete = async () => {
    useRemove.mutate(
      { id },
      {
        onSuccess: (newData) => {
          setIsModalVisible(false);

          queryClient.removeQueries(`/invoice/${id}`, { exact: true });

          queryClient.setQueryData("/invoice", (oldData = []) => {
            return {
              ...oldData,
              results: oldData.results.filter((i) => i.id !== newData.id),
            };
          });
        },
      }
    );
    setIsModalVisible(false);
    fetch();
  };

  const onOk = async () => {
    const values = await form.validateFields();
    values.project = pick(
      projects.find((i) => i.id === values.project),
      ["id", "name"]
    );

    delete values.code;
    values.percentage = 15;


    console.log("Edit");
    usePatch.mutate(values, {
      onSuccess: (newData) => {
        setIsModalVisible(false);

        queryClient.setQueryData(`/invoice/${id}`, () => newData);

        queryClient.setQueryData("/invoice", (oldData = []) => ({
          ...oldData,
          results: oldData.results.map((i) => (i.id === id ? newData : i)),
        }));
      },
    });
    fetch();
  };

  const usd = Form.useWatch(["total", "usd"], form);
  return (
    <div>
      <Button type="primary" onClick={showModal}>
        تعديل
      </Button>
      <Modal
        title="إضافه فاتورة جديدة"
        centered
        width={"70%"}
        destroyOnClose
        visible={isModalVisible}
        // okText={"تعديل"}
        // cancelText={"إلغاء"}
        // onOk={onOk}
        onCancel={onCancel}
        footer={
          <div>
            <Button type="primary" onClick={onOk}>
              تعديل
            </Button>
            <Button onClick={onCancel}>إلغاء</Button>

            <Popconfirm
              title="هل انت متآكد ؟"
              onConfirm={onDelete}
              okText="نعم"
              cancelText="كلا"
            >
              <Button type="primary" danger>
                حذف
              </Button>
            </Popconfirm>
          </div>
        }
      >
        <PageSpinner loading={isLoading}>
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
              ...data,
              date: moment(data?.date),
              project: data?.project?.id,
            }}
            autoComplete="off"
          >
            <Form.Item label="الوصف" name="name" rules={rules.input}>
              <Input />
            </Form.Item>

            <Form.Item label="تاريخ" name="date" rules={rules.date}>
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item label="الصنف" name="category" rules={rules.input}>
              <Input />
            </Form.Item>

            <Form.Item label="نوع" name="type" rules={rules.select}>
              <Select>
                <Select.Option value="Debit">دفع</Select.Option>
                <Select.Option value="Credit">استلام</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="دولار"
              name={["total", "usd"]}
              rules={rules.input}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>

            {usd > 0 && (
              <Form.Item
                label="سعر التحويل"
                name={["total", "exchangeRate"]}
                rules={rules.input}
              >
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
            )}

            <Form.Item
              label="دينار"
              name={["total", "iqd"]}
              rules={rules.input}
            >
              <InputNumber style={{ width: "100%" }} min={0} />
            </Form.Item>

            <Form.Item label="المشروع" name="project" rules={rules.select}>
              <Select>
                {projects.map((i) => (
                  <Select.Option value={i.id}>{i.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </PageSpinner>
      </Modal>
    </div>
  );
}
