import React from 'react';
import {Select} from "antd";

function TablePagination({table}) {

    const currentPage = table.getState().pagination.pageIndex + 1
    const pageSize = table.getState().pagination.pageSize
    const pageCount = table.getPageCount()

    return (
        <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
        >
            {pageCount > 1 && (
                <div className="flex-1 flex justify-start">
                    <button
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        className="mx-2 relative inline-flex items-center px-4 py-2 border border-gray-300
                    text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50
                    disabled:bg-gray-300  disabled:text-gray-500 disabled:cursor-not-allowed"
                    >
                        السابق
                    </button>
                    <button
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50  disabled:bg-gray-300  disabled:text-gray-500 disabled:cursor-not-allowed"
                    >
                        التالي
                    </button>
                </div>
            )}
            <div className="flex-1 flex justify-center text-sm text-gray-700">
                <div>
                    الصفحة <span className="font-medium">{currentPage}</span> من {' '}
                    <span className="font-medium">{pageCount}</span>
                </div>
            </div>

            <div className="flex-1 flex justify-end  space-x-2 space-x-reverse ">
                <Select
                    defaultValue={currentPage}
                    value={currentPage - 1}
                    style={{width: 100}}
                    onChange={(e) => table.setPageIndex(e)}
                >
                    {[...Array(pageCount).keys()].map(i => <Select.Option value={i}>الصفحة {i + 1}</Select.Option>)}
                </Select>


                <Select
                    defaultValue={10}
                    value={pageSize}
                    style={{width: 130}}
                    onChange={(e) => table.setPageSize(e)}
                >
                    <Select.Option value={10}>10 لكل صفحة</Select.Option>
                    <Select.Option value={20}>20 لكل صفحة</Select.Option>
                    <Select.Option value={50}>50 لكل صفحة</Select.Option>
                    <Select.Option value={100}>100 لكل صفحة</Select.Option>
                </Select>
            </div>
        </nav>
    );
}

export default TablePagination;
