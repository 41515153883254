import {getFullUrl, useFetch} from "../../api";
import {OwlTable} from "../owl-table";
import React, {useMemo} from "react";
import moment from "moment";
import {formatBytes} from "../../utils/utils";
import {UploadModal} from "./modal-new";
import {RefreshButton} from "../refresh-button";
import {Button, Tooltip} from "antd";
import {CloudDownloadOutlined} from "@ant-design/icons";
import {DeleteFile} from "./delete-file";
import {UploadEdit} from "./modal-update";


export function Upload({owner}) {

    const {data = {}, isLoading, fetch} = useFetch({url: `/upload/owner/${owner}`})

    const columns = useMemo(() => [
        {
            id: 'code', accessorKey: 'code', header: 'الرمز',
            cell: info => <UploadEdit fetch={fetch} id={info.row.original.id} text={info.getValue()}/>
        },
        {
            accessorFn: row => moment(row.updated).format('DD-MM-YYYY'),
            id: 'date',
            header: 'التاريخ'
        },
        {accessorKey: 'description', id: 'description', header: 'الوصف'},
        {
            accessorFn: row => row?.mimetype.split('/')[0],
            id: 'mimetype',
            header: 'نوع الملف',
        },
        {
            accessorFn: row => row.size,
            cell: info => formatBytes(info.getValue()),
            id: 'size',
            header: 'الحجم',
        },
        {
            id: 'filePath',
            header: '',
            accessorFn: row => getFullUrl(row.filePath),
            cell: info => {
                return <div className="space-x-3 space-x-reverse">
                    <OpenFile link={info.getValue()}/>
                    <DeleteFile id={info.row.original.id} fetch={fetch}/>
                </div>
            }
        },
    ], [data, isLoading]);


    const OpenFile = ({link}) =>
        <Tooltip title="تحميل">
            <Button type="primary" onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}
                    icon={<CloudDownloadOutlined/>}/>
        </Tooltip>

    return <OwlTable
        data={data}
        columns={columns}
        isLoading={isLoading}
        buttons={[<UploadModal owner={owner} fetch={fetch}/>, <RefreshButton fetch={fetch} loading={isLoading}/>]}
    />

}
