import decode from 'jwt-decode'
import {attempt, isError} from 'lodash'
import {createContext, useContext, useEffect, useState} from 'react'
import useLocalStorageState from 'use-local-storage-state'

import {handelRequests} from '../api/api-wrapper'
import {SERVER_URL} from "../api";


const LOCAL_STORAGE_KEY = 'accessToken'
const LOCAL_REFRESH_TOKEN = 'refreshToken'
const LOCAL_STORAGE_USR = 'userData'

const SECURITY_CONTEXT_DEFAULT = {
    login: () => 0,
    logout: () => 0,
    user: {},
    accessToken: '',
    isAuthenticated: false,
}
const SecurityContext = createContext(SECURITY_CONTEXT_DEFAULT)

export const useAuth = () => {
    const auth = useContext(SecurityContext)
    const isAdmin = auth?.user?.role === 'admin'

    return {
        ...auth,
        isAdmin
    }

}

export const SecurityProvider = ({children}) => {
    const [isReady, setIsReady] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [user, setUser] = useLocalStorageState(LOCAL_STORAGE_USR, {})
    const [accessToken, setAccessToken] = useLocalStorageState(
        LOCAL_STORAGE_KEY,
        ''
    )

    const [refreshToken, setRefreshToken] = useLocalStorageState(
        LOCAL_REFRESH_TOKEN,
        ''
    )
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const token = attempt(() => decode(accessToken))
        let newIsAuthenticated = false

        if (!isError(token)) {
            const expiresAt = token.exp * 1000
            newIsAuthenticated = Date.now() < expiresAt
        }
        setIsAuthenticated(newIsAuthenticated)
        setIsReady(true)
    }, [accessToken])

    async function login(userInfo) {
        setIsLoading(true)
        try {
            const data = await handelRequests({
                method: 'POST',
                url: `${SERVER_URL}/auth/login`,
                data: userInfo,
            })

            setIsLoading(false)
            if (!data.tokens.access.token) {
                return {isSuccess: false, data}
            }

            setAccessToken(data.tokens.access.token)
            setRefreshToken(data.tokens.refresh.token)
            setUser(data.user)

            return {isSuccess: true, data}
        } catch (error) {
            setIsLoading(false)
            return {isSuccess: false, data: error}
        }
    }

    async function singup(userInfo) {
        delete userInfo['password-confirm']

        setIsLoading(true)
        try {
            const data = await handelRequests({
                method: 'POST',
                url: `${SERVER_URL}/auth/register`,
                data: userInfo,
            })

            setIsLoading(false)
            if (!data.tokens.access.token) {
                return {isSuccess: false, data}
            }

            setAccessToken(data.tokens.access.token)
            setRefreshToken(data.tokens.refresh.token)
            setUser(data.user)

            return {isSuccess: true, data}
        } catch (error) {
            setIsLoading(false)
            return {isSuccess: false, data: error}
        }
    }

    async function logout() {

        const data = await handelRequests({
            method: 'POST',
            url: `${SERVER_URL}/auth/logout`,
            data: {refreshToken},
        })

        console.log({action: 'logout', data})

        setIsAuthenticated(false)
        setAccessToken(null)
        setRefreshToken(null)
        setUser({})
        localStorage.removeItem('accessToken')
        localStorage.clear()
    }

    if (!isReady) {
        return null
    }

    return (
        <SecurityContext.Provider
            value={{
                user,
                isAuthenticated,
                accessToken,
                isLoading,
                isUserWithinScope: () => true, // takes route scope and compare with user scope TODO
                login,
                logout,
                singup,
            }}
        >
            {children}
        </SecurityContext.Provider>
    )
}
