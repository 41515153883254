import React, {useMemo} from 'react';
import {useFetch} from "../../api";
import {AddWorker} from "./add-worker";
import {Shell} from "../../components/shell";
import {OwlTable} from "../../components/owl-table";
import {EditWorker} from "./edit-worker";
import {formatNumber} from "../../utils/utils";
import PageHeading from "../../components/page-heading";
import AddExchange from "../exchanges/add-exchange";
import {RefreshButton} from "../../components/refresh-button";
import {useAuth} from "../../security";

export function Workers(props) {
    const {isAdmin} = useAuth()

    const {data = {}, isLoading, fetch} = useFetch({url: '/worker'})

    const columns = useMemo(() => [
        isAdmin && {
            id: 'actions',
            accessorKey: 'actions',
            header: 'آوامر',
            cell: info => <div>
                <EditWorker id={info.row.original.id}/>
            </div>,
        },
        {
            id: 'code',
            accessorKey: 'code',
            header: 'الرمز',
        },
        {
            id: 'name',
            accessorKey: 'name',
            header: 'الآسم',
        },
        {
            id: 'salary',
            accessorKey: 'salary',
            header: 'الراتب بالدينار',
            cell: info => formatNumber(info.getValue()),
        },
        {
            accessorFn: row => row?.status === 'Active' ? 'فعال' : row.status === 'Paused' ? "متوقف" : 'ملغي',
            id: 'status',
            header: 'الحالة',
        },
    ], [data, isLoading]);


    return (
        <Shell currentPage="worker" isLoading={isLoading}>
            <PageHeading
                title={'العمال'}
                subtitle={'كشف العمال في الشركة.'}
            />
            <OwlTable
                data={data?.results || []}
                columns={columns}
                buttons={[isAdmin && <AddWorker/>, <RefreshButton fetch={fetch} loading={isLoading}/>]}
            />
        </Shell>
    );
}
