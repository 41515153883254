import React from 'react';
import {Button, Popconfirm} from "antd";

export function ModalFormFooter({onOk, onCancel, onDelete, onDeleteLoading, onUpdateLoading}) {
    return (
        <div>
            <Button type="primary" loading={onUpdateLoading} onClick={onOk}>تعديل</Button>
            <Button onClick={onCancel}>إلغاء</Button>

            <Popconfirm
                title="هل انت متآكد ؟"
                onConfirm={onDelete}
                okText="نعم"
                cancelText="كلا"
            >
                <Button type="primary" loading={onDeleteLoading} danger>
                    حذف
                </Button>
            </Popconfirm>
        </div>
    );
}
