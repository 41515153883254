import {Spin} from 'antd'
import {useNavigate} from "react-router-dom";

import {useAuth} from '../../../security'
import {useEffect} from "react";

export function LogoutPage() {
    const {logout} = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        logout().then(() => {
            navigate("/", {replace: true});
        })
    }, [])

    return (
        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
            <div className="max-w-max mx-auto flex flex-col justify-center items-center">
                <div className="mt-3 text-5xl flex-1 m-auto mb-12">
                    Axio<strong>matic</strong>
                </div>
                <div className="mb-10 text-2xl">تسجيل الخروج</div>
                <Spin size="large"/>
            </div>
        </div>
    )
}
