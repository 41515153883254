import {Suspense} from 'react'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {ConfigProvider} from "antd";

import {AppRouter} from './app-router'
import {PageSpinner} from './components/page-spinner'
import {SecurityProvider} from './security'

function App() {
    const queryClient = new QueryClient()

    return <Suspense fallback={<PageSpinner/>}>
        <ConfigProvider direction="rtl">
            <SecurityProvider>
                <QueryClientProvider client={queryClient}>
                    <AppRouter/>
                    <ReactQueryDevtools/>
                </QueryClientProvider>
            </SecurityProvider>
        </ConfigProvider>
    </Suspense>
}

export default App;
