import {TableHead} from "./table-head";
import {TableBody} from "./table-body";
import {TableFooter} from "./table-footer";
import TablePagination from "./table-pagination";
import {PageSpinner} from "../page-spinner";

export function Table({table, isFilter, isLoading}) {

    return (
        <div className="mx-auto px-4 px-8">
            <div className="mx-auto">
                <div className="-mx-8 mt-3">
                    <PageSpinner loading={isLoading}>
                        <div className="overflow-scroll shadow ring-1 ring-black ring-opacity-5">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                <TableHead table={table} isFilter={isFilter}/>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                <TableBody table={table}/>
                                </tbody>
                                {/*<tfoot>*/}
                                {/*<TableFooter table={table}/>*/}
                                {/*</tfoot>*/}
                            </table>
                        </div>
                        <TablePagination table={table}/>
                    </PageSpinner>
                </div>
            </div>
        </div>
    );
}
